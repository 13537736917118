import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, Card, CardBody, CardTitle } from 'reactstrap';
import axios from 'axios';

import NavbarPage from '../components/NavbarPage';

const CekData = () => {
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalHeader, setModalHeader] = useState('');

  const toggleModal = () => setModal(!modal);

  // Skema validasi menggunakan Yup
  const validationSchema = Yup.object({
    nim: Yup.string()
      .required('NIM wajib diisi')
      .matches(/^\d+$/, 'NIM hanya boleh berisi angka')
      
  });

  const formatNowa = (nowa) => {
    // Mengubah menjadi string untuk memudahkan manipulasi
    const nowaStr = nowa.toString();
    
    // Memastikan panjangnya cukup
    if (nowaStr.length < 7) {
      return 'Invalid Number';
    }
  
    // Memformat menjadi 4 angka pertama, tanda *, dan 2 angka terakhir
    const firstFour = nowaStr.slice(0, 5);
    const lastTwo = nowaStr.slice(-2);
    
    // Menghitung panjang angka tengah
    const middleStars = '*'.repeat(nowaStr.length - 7); // Mengganti angka tengah dengan '*'
    
    return `${firstFour}${middleStars}${lastTwo}`;
  };

  const formatEmail = (email) => {
    const atIndex = email.indexOf('@');
    
    // Memastikan email valid dan ada tanda '@'
    if (atIndex === -1) return 'Invalid Email';
  
    const firstThree = email.slice(0, 3); // 3 karakter pertama
    const beforeAt = email[atIndex - 1] || ''; // 1 karakter sebelum '@'
    const afterAt = email.slice(atIndex); // semua karakter setelah '@'
    
    // Menghitung jumlah karakter yang akan diganti dengan '*'
    const remainingStars = '*'.repeat(atIndex - 4); 
  
    // Menggabungkan hasil
    return `${firstThree}${remainingStars}${beforeAt}${afterAt}`;
  };
  
  

  // Fungsi untuk mengecek NIM menggunakan Axios
  const checkNim = async (values) => {
    try {
      const response = await axios.post('https://rlagency.id/api/cek_nim.php', {
        nim: values.nim,
        name: values.name,
        email: values.email,
        fakultas: values.fakultas,
        jurusan: values.jurusan,
        tingkat: values.tingkat,
        gender: values.gender,
        graduationDate: values.graduationDate,
        nowa: values.nowa,
        nowisuda: values.nowisuda,
        date_created: values.date_created,
        dikirim: values.dikirim,
        foto: values.foto,
        materi: values.materi,
        sertifikat: values.sertifikat,
      });

      const data = response.data;

      if (data.exists === true) {
        // Mengambil dan memformat date_created
        const dateCreated = new Date(data.date_created.replace(" ", "T")); // Mengganti spasi dengan 'T' untuk ISO format
        const formattedDate = dateCreated.toLocaleDateString('id-ID', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        // Menentukan status foto, sertifikat, dan materi
        const fotoStatus = data.foto === 0 ? 'In Progress' : 'Telah selesai dan dikirim';
        const sertifikatStatus = data.sertifikat === 0 ? 'In Progress' : 'Telah selesai dan dikirim';
        const materiStatus = data.materi === 0 ? 'In Progress' : 'Telah selesai dan dikirim';

        // Memformat nomor Whatsapp
        const formattedNowa = formatNowa(data.nowa);
        // Memformat email
        const formattedEmail = formatEmail(data.email);

        setModalHeader(`${data.name}`);

        setModalMessage(
            // `Data terdaftar dengan informasi : \nNama : ${data.name} <br/>
            // E-mail : ${data.email}, ${data.fakultas}, ${data.sertifikat}`
              `<table style="width: 100%; border-collapse: collapse;">
                  <tbody>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">NIM</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${data.nim}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">Terdaftar Tgl</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${formattedDate}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">E-mail</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${formattedEmail}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">No Whatsapp</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${formattedNowa}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">Fak./ Jurusan</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${data.fakultas} / ${data.jurusan}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">Tingkat</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${data.tingkat}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">Tanggal Wisuda</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${data.graduationDate}</td>
                      </tr>
                  </tbody>
                  
              </table>
              <br/>
              <table style="width: 100%; border-collapse: collapse;">
                  <tbody>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">Foto Anda</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${fotoStatus}</td>
                      </tr>
                      <tr>
                          <td style="border: 1px solid #ddd; padding: 8px;">Sertifikat Anda</td>
                          <td style="border: 1px solid #ddd; padding: 8px;">${sertifikatStatus}</td>
                      </tr>
                  </tbody>
                  
              </table>`          
        );
      } else {
        setModalMessage('Anda belum terdaftar sebagai peserta Foto Wisuda');
      }

      toggleModal();
    } catch (error) {
      setModalMessage('Terjadi kesalahan. Silakan coba lagi.');
      toggleModal();
    }
  };

  return (
    <div className="container mt-5" >
      <NavbarPage />

      <Card className="p-4" style={{ borderRadius: '10px', backgroundColor: '#f8f9fa', marginTop:'100px' }}>
        <CardBody>
          {/* <CardTitle tag="h4" className="text-center mb-4">Cek Foto Wisuda</CardTitle> */}
          <Formik
            initialValues={{ nim: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              checkNim(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormGroup>
                  <Label for="nim"><b>Masukkan NIM anda :</b></Label>
                  <Field
                    name="nim"
                    as={Input}
                    type="text"
                    id="nim"
                    placeholder="Masukkan NIM"
                  />
                  <ErrorMessage name="nim" component="div" className="text-danger" />
                </FormGroup>

                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Cek Foto
                </Button>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>

      {/* Modal untuk menampilkan hasil pengecekan */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Nama : {modalHeader}</ModalHeader>
        {/* <ModalBody>{modalMessage}</ModalBody> */}
        <ModalBody>
                    {/* Menggunakan dangerouslySetInnerHTML untuk merender HTML */}
                    <div dangerouslySetInnerHTML={{ __html: modalMessage }} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CekData;
