import React from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, FormGroup, Label, Input, FormFeedback, Container, ButtonGroup, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useState } from 'react';
import './style.css';

const SignupSchema = Yup.object().shape({

  nim: Yup.number()
    .typeError('NIM harus berupa angka')
    .integer('NIM harus berupa bilangan bulat')
    .required('NIM wajib diisi!')
    .positive('NIM harus angka positif')
    .test(
      'len',
      'NIM harus terdiri dari minimal 9 angka',
      val => val && val.toString().length >= 9
    ),
  name: Yup.string()
    .required('Nama wajib diisi!' ),

  email: Yup.string()
    .email('Email tidak valid!')
    .required('Email wajib diisi!'),
  
  nowa: Yup.string()
    .required('Nomor WA wajib diisi!')
    .matches(/^(08|\+62)/, 'Nomor WA tidak valid')
    .min(10, 'Nomor WA tidak valid'),

  nowisuda: Yup.number()
    .typeError('Nomor urut wisuda tidak valid')
    .positive('Nomor urut wisuda tidak valid')
    .integer('Nomor urut wisuda tidak valid')
    .required('Nomor urut wisuda wajib diisi!'),

  // password: Yup.string()
  //   .min(6, 'Password minimal 6 karakter')
  //   .required('Password wajib diisi!'),

  gender: Yup.string()
    .oneOf(['Laki-laki', 'Perempuan'], 'Jenis Kelamin harus dipilih!')
    .required('Jenis kelamin wajib diisi!'),
  
  graduationDate: Yup.string()
    .required('Tanggal wisuda wajib diisi!'),
});

const SignupForm = () => {
  const [open, setOpen] = useState('0');

  const toggle = (id) => {
  if (open === id) {
      setOpen();
  } else {
      setOpen(id);
  }
};

  // Opsi dropdown kedua berdasarkan pilihan pertama
const getSecondOptions = (firstSelection) => {
  if (firstSelection === 'Fakultas Ilmu Komputer') {
    return ['Manajemen Informatika', 'Sistem Komputer', 'Sistem Informasi', 'Teknik Informatika', 'Teknologi Informasi'];
  } else if (firstSelection === 'Fakultas Teknik') {
    return ['Teknik Industri', 'Teknik Sipil'];
  } else if (firstSelection === 'Fakultas Desain Komunikasi Visual') {
      return ['Desain Komunikasi Visual'];
  } else if (firstSelection === 'Fakultas Ekonomi dan Bisnis') {
      return ['Akuntansi', 'Manajemen'];
  } else if (firstSelection === 'Fakultas Psikologi') {
      return ['Psikologi'];
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan') {
      return ['Pendidikan Teknik Informatika', 'Bimbingan Konseling', 'Bahasa Inggris'];
  }
  return [];
};

// Opsi radio button berdasarkan kombinasi pilihan
const getRadioOptions = (firstSelection, secondSelection) => {
  if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Manajemen Informatika') {
    return ['D3 (Diploma-3)'];
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Sistem Komputer') {
    return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Sistem Informasi') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Teknik Informatika') {
      return ['S1 (Strata-1)', 'S2 (Strata-2)'];
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Teknologi Informasi') {
      return ['S3 (Strata-3)'];
  } else if (firstSelection === 'Fakultas Teknik' && secondSelection === 'Teknik Industri') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Teknik' && secondSelection === 'Teknik Sipil') {
      return ['S1 (Strata-1)'];
  }  else if (firstSelection === 'Fakultas Desain Komunikasi Visual' && secondSelection === 'Desain Komunikasi Visual') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Ekonomi dan Bisnis' && secondSelection === 'Akuntansi') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Ekonomi dan Bisnis' && secondSelection === 'Manajemen') {
      return ['S1 (Strata-1)','S2 (Strata-2)', 'S3 (Strata-3)'];
  } else if (firstSelection === 'Fakultas Psikologi' && secondSelection === 'Psikologi') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan' && secondSelection === 'Pendidikan Teknik Informatika') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan' && secondSelection === 'Bimbingan Konseling') {
      return ['S1 (Strata-1)'];
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan' && secondSelection === 'Bahasa Inggris') {
      return ['S1 (Strata-1)'];
  }
  // Tambahkan logika lainnya jika diperlukan
  return [];
};

// Tentukan default radio berdasarkan kombinasi dropdown
const getDefaultRadioOption = (firstSelection, secondSelection) => {
  if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Manajemen Informatika') {
      return 'D3 (Diploma-3)';
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Sistem Komputer') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Sistem Informasi') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Ilmu Komputer' && secondSelection === 'Teknologi Informasi') {
      return 'S3 (Strata-3)';
  } else if (firstSelection === 'Fakultas Teknik' && secondSelection === 'Teknik Industri') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Teknik' && secondSelection === 'Teknik Sipil') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Desain Komunikasi Visual' && secondSelection === 'Desain Komunikasi Visual') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Ekonomi dan Bisnis' && secondSelection === 'Akuntansi') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Psikologi' && secondSelection === 'Psikologi') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan' && secondSelection === 'Pendidikan Teknik Informatika') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan' && secondSelection === 'Bimbingan Konseling') {
      return 'S1 (Strata-1)';
  } else if (firstSelection === 'Fakultas Keguruan dan Ilmu Pendidikan' && secondSelection === 'Bahasa Inggris') {
      return 'S1 (Strata-1)';
  }
  return ''; // Default fallback
};

const [modalOpen, setModalOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <Container>
    <Container>

      <Formik
        initialValues={{
          nim: '',
          name: '',
          email: '',
          nowa: '',
          nowisuda: '',
          // password: '',
          firstDropdown: '',
          secondDropdown: '',
          radioOption: '',
          gender: '',
          graduationDate: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { resetForm }) => {
          // Modifikasi nomor WA, jika diawali 0, ubah menjadi +62
          if (values.nowa.startsWith('0')) {
            values.nowa = values.nowa.replace(/^0/, '+62');
          }
        
          axios.post('https://rlagency.id/api/cek_nim.php', { nim: values.nim })
            .then(checkResponse => {
              console.log('Response dari cek_nim:', checkResponse.data); // Log respons yang benar
              if (checkResponse.data.exists) {
                setModalMessage(`NIM sudah terdaftar dengan nama: ${checkResponse.data.name}!`);
                toggleModal();
                return Promise.reject('NIM exists'); // Menghentikan chain jika NIM sudah ada
              }
        
              // Jika NIM tidak ada, lanjutkan ke pendaftaran
              return axios.post('https://rlagency.id/api/register.php', values, {
                headers: {
                  'Content-Type': 'application/json',
                }
              });
            })
            .then(registerResponse => {
              console.log('Response dari register:', registerResponse.data); // Akses respons yang benar
              setModalMessage('Pendaftaran berhasil! Silahkan klik menu CEK DATA di pojok kanan atas untuk memastikan pendaftaran anda!');
              toggleModal();
              resetForm();
            })
            .catch(error => {
              if (error !== 'NIM exists') { // Mengabaikan error yang sengaja dilempar
                console.error("Ada Kesalahan: ", error);
                setModalMessage('Terjadi kesalahan. Silakan coba lagi.');
                toggleModal();
              }
            });
        }}
        
      >
        {({ values, errors, touched, isValid, setFieldValue }) => (
          <Form>
            {/* Field untuk NIM */}
            <FormGroup className="form-floating mb-3">
              <Field
                name="nim"
                as={Input}
                type="number"
                placeholder="NIM"
                invalid={!!errors.nim && touched.nim}
              />
              <Label for="nim">NIM</Label>
              <ErrorMessage name="nim" component={FormFeedback} />
            </FormGroup>
            
            {/* Field untuk Nama */}
            <FormGroup className="form-floating mb-3">
              <Field
                name="name"
                placeholder="Nama"
                as={Input}
                invalid={!!errors.name && touched.name}
              />
              <Label for="name">Nama Lengkap</Label>
              <ErrorMessage name="name" component={FormFeedback} />
            </FormGroup>

            {/* Field untuk Email */}
            <FormGroup className='form-floating mb-3'>
              <Field
                name="email"
                placeholder="E-Mail"
                type="email"
                as={Input}
                invalid={!!errors.email && touched.email}
              />
              <Label for="email">E-mail</Label>
              <ErrorMessage name="email" component={FormFeedback} />
            </FormGroup>

            {/* Field untuk Nomor WhatsApp */}
            <FormGroup className="form-floating mb-3">
              <Field
                name="nowa"
                placeholder="Nomor WhatsApp"
                type="text"
                as={Input}
                invalid={!!errors.nowa && touched.nowa}
              />
              <Label for="nowa">Nomor WhatsApp</Label>
              <ErrorMessage name="nowa" component={FormFeedback} />
            </FormGroup>

            {/* Dropdown pertama */}
            <FormGroup className="mb-3">
              <Label for="firstDropdown">Fakultas</Label>
              <Field
                as={Input}
                type="select"
                name="firstDropdown"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setFieldValue("firstDropdown", selectedValue);
                  setFieldValue("secondDropdown", ""); // Reset dropdown kedua jika dropdown pertama berubah
                  setFieldValue("radioOption", ""); // Reset radio button jika pilihan berubah
                }}
              >
                <option value="">-Pilih Salah Satu-</option>
                <option value="Fakultas Ilmu Komputer">FILKOM - Fakultas Ilmu Komputer</option>
                <option value="Fakultas Teknik">FT - Fakultas Teknik</option>
                <option value="Fakultas Desain Komunikasi Visual">FDKV - Fakultas Desain Komunikasi Visual</option>
                <option value="Fakultas Ekonomi dan Bisnis">FEB - Fakultas Ekonomi dan Bisnis</option>
                <option value="Fakultas Psikologi">FPSI - Fakultas Psikologi</option>
                <option value="Fakultas Keguruan dan Ilmu Pendidikan">FKIP - Fakultas Keguruan dan Ilmu Pendidikan</option>
              </Field>
            </FormGroup>

            {/* Dropdown kedua - tergantung dari pilihan dropdown pertama */}
            {values.firstDropdown && (
              <FormGroup className="mb-3">
                <Label for="secondDropdown">Jurusan</Label>
                <Field
                  as={Input}
                  type="select"
                  name="secondDropdown"
                  onChange={(e) => {
                    const secondValue = e.target.value;
                    setFieldValue("secondDropdown", secondValue);

                    // Set nilai default radio button berdasarkan dropdown
                    const defaultRadio = getDefaultRadioOption(values.firstDropdown, secondValue);
                    setFieldValue("radioOption", defaultRadio);
                  }}
                >
                  <option value="">-Pilih-</option>
                  {getSecondOptions(values.firstDropdown).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            )}

            {/* Radio button - tergantung dari kombinasi pilihan */}
            {values.firstDropdown && values.secondDropdown && (
              <FormGroup tag="fieldset" className="mb-3">
                <Label>Jenjang Pendidikan</Label>
                <br />
                <ButtonGroup>
                  {getRadioOptions(values.firstDropdown, values.secondDropdown).map((option) => (
                    <Button
                      outline
                      key={option}
                      style={{
                        backgroundColor: values.radioOption === option ? '#FFDC00' : '#fff', // Warna kustom saat dipilih
                        color: '#000000',
                        borderColor: '#DEE2E6'
                      }}
                      onClick={() => setFieldValue('radioOption', option)}
                      active={values.radioOption === option}
                    >
                      {option}
                    </Button>
                  ))}
                </ButtonGroup>
              </FormGroup>
            )}

            {/* Radio button Jenis Kelamin */}
            <FormGroup tag="fieldset" className="mb-3">
              <Label>Jenis Kelamin</Label>
              <br />
              <ButtonGroup>
                <Button
                  outline
                  style={{
                    backgroundColor: values.gender === 'Laki-laki' ? '#FFDC00' : '#fff', // Warna kustom saat dipilih
                    color: '#000000',
                    borderColor: '#DEE2E6'
                  }}
                  onClick={() => setFieldValue('gender', 'Laki-laki')}
                >
                  Laki-laki
                </Button>
                <Button
                  outline
                  // color={values.gender === 'Wanita' ? 'primary' : 'secondary'}
                  style={{
                    backgroundColor: values.gender === 'Perempuan' ? '#FFDC00' : '#fff', // Warna kustom saat dipilih
                    color: '#000000',
                    borderColor: '#DEE2E6'
                  }}
                  onClick={() => setFieldValue('gender', 'Perempuan')}
                >
                  Perempuan
                </Button>
              </ButtonGroup>
              <ErrorMessage name="gender" component={FormFeedback} />
            </FormGroup>

            {/* Dropdown Tanggal Wisuda */}
            <FormGroup className="mb-3">
              <Label for="graduationDate">Tanggal Wisuda</Label>
              <Field
                as={Input}
                type="select"
                name="graduationDate"
              >
                <option value="">-Pilih-</option>
                <option value="Senin">Senin</option>
                <option value="Selasa">Selasa</option>
                <option value="Rabu">Rabu</option>
              </Field>
              <ErrorMessage name="graduationDate" component={FormFeedback} />
            </FormGroup>

            {/* Field untuk Nomor Urut Wisuda */}
            <FormGroup className="form-floating mb-3">
              <Field
                name="nowisuda"
                placeholder="Nomor Urut Wisuda"
                type="number"
                as={Input}
                invalid={!!errors.nowisuda && touched.nowisuda}
              />
              <Label for="nowisuda">Nomor Urut Wisuda</Label>
              <ErrorMessage name="nowisuda" component={FormFeedback} />
            </FormGroup>
            
            {/* Accordion dengan informasi */}
            <br />
            <Accordion open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader 
                  targetId='1'>
                  Informasi Tambahan
                </AccordionHeader>
                <AccordionBody accordionId='1'>
                  <p>
                    Setiap mahasiswa akan mendapatkan 3 softfile moment foto saat wisuda:
                  </p>
                  <ul>
                    <li>Penyerahan Ijazah Bersama Dekan</li>
                    <li>Pemindahan Toga Bersama Rektor</li>
                    <li>Foto Gaya bebas</li>
                  </ul>
                  <p> 
                    Softfile akan dikirimkan Via Whatsapp / email yang sudah didaftarkan [pastikan data yang diisikan sudah benar].
                  </p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            <br/>

            {/* Tombol Submit */}
            <Button type="submit" color="primary" disabled={!isValid}>
              Daftar
            </Button>
          </Form>
        )}
        
      </Formik>
    </Container>

    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Informasi</ModalHeader>
      <ModalBody>
        {modalMessage}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Tutup</Button>
      </ModalFooter>
    </Modal>


    </Container>

    
  );
};

export default SignupForm;
