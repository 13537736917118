import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';

import {
  CSidebarHeader,
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CContainer,
  CRow,
  CCol,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cilBadge } from '@coreui/icons';

import Menu1 from './Menu1';
import Menu2 from './Menu2';
import Menu3 from './Menu3';
import DashboardDefault from './dashboardDefault';

const Dashboard1 = () => {
  const [activeMenu, setActiveMenu] = useState('');

  const renderContent = () => {
    switch (activeMenu) {
      case 'menu1':
        return <Menu1 />;
      case 'menu2':
        return <Menu2 />;
      case 'menu3':
        return <Menu3 />;
      default:
        return <DashboardDefault />;
    }
  };

  const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('expiry');
        localStorage.removeItem('loginTime'); // Hapus waktu login
        navigate('/login');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expiry = localStorage.getItem('expiry');
    
        // Redirect to login page if token does not exist or if token is expired
        if (!token || new Date().getTime() > expiry) {
            setIsModalOpen(true);
        }
    }, [navigate]);

    const handleModalClose = () => {
        setIsModalOpen(false);
        navigate('/login'); // Redirect to login after modal closes
    };

    // Fetch users data from API
    useEffect(() => {
        
        const fetchData = async () => {
            try {
                const response = await axios.get('https://rlagency.id/api/get_users.php');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

     // Fungsi untuk menentukan apakah item menu aktif atau tidak
    const isActiveMenu = (menu) => activeMenu === menu;

  return (
    <CContainer fluid>
        {/* Modal for access denied */}
            <Modal isOpen={isModalOpen} toggle={handleModalClose}>
                <ModalHeader toggle={handleModalClose}>Akses Ditolak</ModalHeader>
                <ModalBody>
                    Anda tidak memiliki akses halaman ini. Silakan login terlebih dahulu.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleModalClose}>OK</Button>
                </ModalFooter>
            </Modal>

      <CRow >

     
        {/* Sidebar */}
        <CSidebar className="border-end cs-sidebar" colorScheme="dark" style={{paddingBottom:'20px', overflowY: 'auto'}}>
          <CSidebarHeader className="border-bottom">
            <CSidebarBrand onClick={() => setActiveMenu('DashboardDefault')}>
              <img 
                src="./rla_logo_long.png" // Ganti dengan URL gambar Anda
                alt="Logo" 
                style={{ width: '100%', height: 'auto' }} // Sesuaikan ukuran gambar sesuai kebutuhan
              /> 
            </CSidebarBrand>
          </CSidebarHeader>
          <CSidebarNav>
            <CNavTitle>Daftar Menu</CNavTitle>

            <CNavItem href="#daftar" onClick={() => setActiveMenu('menu1')}
              style={{ backgroundColor: isActiveMenu('menu1') ? '#F9B115' : 'transparent', color:isActiveMenu('menu1') ? 'black' : 'white' }} >
              <CIcon customClassName="nav-icon" icon={cilBadge} style={{ color: isActiveMenu('menu1') ? 'black' : 'white' }} />Foto Wisuda
            </CNavItem>

            {/* <CNavItem href="#" onClick={() => setActiveMenu('menu2')}>
              <CIcon customClassName="nav-icon" icon={cilBadge} />Loker
            </CNavItem>

            <CNavItem href="#" onClick={() => setActiveMenu('menu3')}>
              <CIcon customClassName="nav-icon" icon={cilBadge} />Test
            </CNavItem> */}

          </CSidebarNav>
          
          <Button color="danger" onClick={handleLogout}>Logout</Button>
        </CSidebar>

        <CCol style={{padding:'0', margin:'0'}} >
          {/* Main Content */}
          <div className="wrapper min-vh-100" style={{backgroundColor:'#F3F4F7', paddingTop:'20px'}}>
            {/* <Navbar 
                style={{
                    borderRadius: '0px',
                    position: 'fixed', // Menetapkan posisi navbar tetap
                    top: '0px', // Mengatur navbar di bagian atas
                    zIndex: '1000', // Memastikan navbar di atas konten lainnya
                    padding:'15px',
                    justifyContent:'space-between',
                    width:'80%',
                    backgroundColor: '#fff',
                    borderBottom:'1px'
                  }}
            >
                        <Nav className="me-auto" navbar>
                            <NavItem>
                                <NavLink href="#">Dashboard</NavLink>
                            </NavItem>
                        </Nav>
                        <Button color="danger" onClick={handleLogout}>Logout</Button>
            </Navbar> */}
            
            <Container fluid>
                {renderContent()}
            </Container>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Dashboard1;
