import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa'; // Import ikon tanda tanya

const NavbarPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
    <Navbar style={{ backgroundColor: '#F0B502' }} light expand="md"  fixed="top">
        <NavbarBrand href="/">
          <img
            src="./logo-grad.svg" // Ganti dengan URL logo Anda
            alt="Logo"
            style={{ width: '100px', height: '50px' }}
          />
          <b>WISUDA UPI YPTK</b>
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink href="/" style={{ fontWeight: 'bold' }}>Pendaftaran</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/cekdata" style={{ fontWeight: 'bold' }}>Cek Data</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/bantuan" style={{ fontWeight: 'bold' }}>
                <FaQuestionCircle style={{ marginRight: '5px', fontSize: '1.5em' }} />               </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
    </Navbar>
  </header>
  );
};

export default NavbarPage;
